import http from "./httpService";

export async function getReports() {
  const getReportsUrl =
    process.env.REACT_APP_SEMBRA_URL + "/sembraCash/getAllPayouts";

  const reports = await http.post(getReportsUrl);
  return reports;
  //   return await http.get(getReportsUrl).catch((err) => {
  //     console.log("ERROR: ", err);
  //   });
}

export async function getDateRangeReports(startDate, endDate) {
  const getReportsUrl =
    process.env.REACT_APP_SEMBRA_URL + "/sembraCash/filterReportsByDateRange";

  const reports = await http.post(getReportsUrl, { startDate, endDate });
  return reports;
}

export default {
  getReports,
  getDateRangeReports,
};
