import { GlobalFilter } from "./GlobalFilter";
import { DateRange } from "./DateFilter";

export const FilterBar = ({
  filter,
  setFilter,
  handleDateRangeFilter,
  dateFilter,
  hasDateRange,
}) => {
  return (
    <div className="filter-bar">
      <GlobalFilter filter={filter} setFilter={setFilter} />
      {hasDateRange && (
        <DateRange
          handleDateRangeFilter={handleDateRangeFilter}
          dateFilter={dateFilter}
        />
      )}
    </div>
  );
};
