import { IoSearchSharp } from "react-icons/io5";

export const GlobalFilter = ({ filter, setFilter }) => {
  return (
    <div className="global-filter">
      <span style={{ marginRight: "1.75rem" }}>Text Search: </span>
      <br />
      <div className="icon-input">
        {/* <div style={{ position: "absolute", margin: "0 4px" }}> */}
        <IoSearchSharp />
        {/* </div> */}
        <input
          // style={{ paddingLeft: "20px" }}
          type="text"
          value={filter || ""}
          onChange={(e) => setFilter(e.target.value)}
          placeholder="Type to search"
        />
      </div>
    </div>
  );
};
