import { useMemo, useEffect } from "react";
// Link to useTable tutorial
// https://www.youtube.com/watch?v=YwP4NAZGskg&list=PLC3y8-rFHvwgWTSrDiwmUsl4ZvipOw9Cz
import { useTable, useSortBy, useGlobalFilter } from "react-table";
import { Table } from "react-bootstrap";
import { AiFillCaretDown, AiFillCaretUp } from "react-icons/ai";
import { useNavigate, Link } from "react-router-dom";
import { VscSmiley } from "react-icons/vsc";
import { FilterBar } from "../filters/FilterBar";
import { format } from "date-fns";
import {
  getFullName,
  formatCurrency,
} from "../../../helperFunctions/formatting";

export default function SelectReportsTable({
  payoutList,
  handleDateRangeFilter,
  dateFilter,
  dateRangeFilterResults,
}) {
  const navigate = useNavigate();

  const COLUMNS = [
    {
      Header: "Status",
      accessor: "status",
    },
    {
      Header: "Name",
      // accessor: (row) => `${row.empLastName}, ${row.empFirstName}`,
      accessor: (row) => getFullName(row.empID),
    },
    {
      Header: "Phone",
      accessor: "phoneNumber",
    },
    {
      Header: "Payout",
      // accessor: "amount",
      // accessor: (row) =>
      //   `${parseFloat(row.amount).toLocaleString("en-US", {
      //     style: "currency",
      //     currency: "USD",
      //     minimumFractionDigits: 2,
      //     maximumFractionDigits: 4,
      //   })}`,
      accessor: (row) => formatCurrency(row.amount),
    },
    {
      Header: "Reason",
      accessor: "message",
    },
    {
      Header: "Send Date",
      accessor: (row) => format(row.sendDate, "MM-dd-y"),
    },
  ];

  const colData = useMemo(() => COLUMNS, []);
  const data =
    dateFilter.startDate && dateFilter.endDate
      ? dateRangeFilterResults
      : payoutList;
  //   const initialState = { hiddenColumns: ["sendDate"] };

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns: colData,
      data: data,
      // initialState,
    },
    useGlobalFilter,
    useSortBy
  );

  const { globalFilter } = state;

  //   const handleRowClick = (selectedEmployee) => {
  //     navigate(`/view-report/${selectedEmployee.values.empID}`);
  //   };

  return (
    <>
      <FilterBar
        filter={globalFilter}
        setFilter={setGlobalFilter}
        handleDateRangeFilter={handleDateRangeFilter}
        dateFilter={dateFilter}
        dateRangeFilterResults={dateRangeFilterResults}
        hasDateRange={true}
      />
      <div className="table-container">
        <Table striped bordered {...getTableProps}>
          <thead>
            {headerGroups.map((group, index) => (
              <tr key={index} {...group.getHeaderGroupProps()}>
                {group.headers.map((column) => (
                  <th {...column.getHeaderProps(column.getSortByToggleProps())}>
                    {column.render("Header")}
                    <span>
                      {/* {column.isSorted ? (
                        column.isSortedDesc ? (
                          <AiFillCaretDown className="ms-2" />
                        ) : (
                          <AiFillCaretUp className="ms-2" />
                        )
                      ) : (
                        <VscSmiley className="ms-2" />
                      )} */}
                      {column.isSorted &&
                        (column.isSortedDesc ? (
                          <AiFillCaretDown className="ms-2" />
                        ) : (
                          <AiFillCaretUp className="ms-2" />
                        ))}
                    </span>
                  </th>
                ))}
              </tr>
            ))}
            {/* {headerGroups.map((group, index) => {
              console.log(group);
            })} */}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row) => {
              prepareRow(row);
              return (
                <tr
                  {...row.getRowProps()}
                  // onClick={() => handleRowClick(row)}
                  // className="clickable-row"
                >
                  {row.cells.map((cell) => {
                    return (
                      <td {...cell.getCellProps()}>{cell.render("Cell")}</td>
                    );
                  })}
                </tr>
              );
            })}
            {/* {rows.map((row) => {
              console.log(row);
            })} */}
          </tbody>
        </Table>
      </div>
    </>
  );
}
