import { useEffect, useState, useRef } from "react";
import { useLoaderData, useParams, useNavigate } from "react-router-dom";
import { Form, Button } from "react-bootstrap";
import { Formik } from "formik";
import { HiHome } from "react-icons/hi";
import {
  getCashEmployee,
  sendDOTSPayout,
} from "../../../services/employeeService";
import {
  formatCamelCase,
  formatCurrency,
} from "../../../helperFunctions/formatting";

export default function SendMoney() {
  const [employee, setEmployee] = useState({});
  const [showConfirm, setShowConfirm] = useState(null);
  const [confirmationMessage, setConfirmationMessage] = useState("");
  const [payoutDisable, setPayoutDisable] = useState(false);
  const [showSuccess, setShowSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState("");
  let { empID } = useParams();
  const navigate = useNavigate();
  const ref = useRef(null);

  useEffect(() => {
    // using setTimeout to simulate async (auth) event
    getCashEmployee(empID).then((res) => {
      const employeePull = res.data;
      setEmployee(employeePull);
    });
  }, []);

  const handleNext = (values) => {
    if (!values.payReason || !values.cashAmount) {
      alert("Please include both Payout Reason and Cash Amount");
    } else if (parseFloat(values.cashAmount.replace(/[^0-9.]/g, "")) > 100) {
      alert("Payout can not exceed $100");
    } else {
      setShowConfirm(true);
      setConfirmationMessage(
        `Submitting ${values.cashAmount} to ${employee.empFirstName
        } ${employee.empLastName}`
      );
    }
  };

  const handleCurrencyFormat = async (event) => {
    await ref.current.setFieldValue('cashAmount', "$"+ ref.current.values.cashAmount.replace(/[^0-9.]/g, ""))
  }

  const handleCurrencyBlur = async (event) => {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD'
    });

    await ref.current.setFieldValue('cashAmount', formatter.format(ref.current.values.cashAmount.replace(/[^0-9.]/g, "")))
  }

  const handleCashSubmit = async (values) => {
    // console.log(
    //   `Submitting ${values.cashAmount} to ${employee.firstName}, ${employee.lastName}`,
    //   values
    // );
    // console.log(values)

    let payoutObject = {
      empID: employee.empID,
      message: values.payReason,
      amount: parseFloat(values.cashAmount.replace(/[^0-9.]/g, "")) * 100,
      phoneNumber: employee.empPhoneNumber.replace(/\D/g, ""),
    };

    await sendDOTSPayout(payoutObject).catch((err) => console.log(err));

    setPayoutDisable(true);
    setShowSuccess(true);

    setSuccessMessage(
      `Successfully Sent ${values.cashAmount} to ${employee.empFirstName
      } ${employee.empLastName}`
    );
    setConfirmationMessage("");
  };

  return (
    <section className="main-section">
      <h1 className="text-center">Send Money</h1>
      <section className="section-content">
        <h2>Employee Information</h2>
        <div className="d-flex justify-content-around">
          <div>
            {Object.keys(employee).map(
              (key, index) =>
                index !== 0 &&
                index !== 6 && (
                  <p key={key}>
                    <strong>{formatCamelCase(key)} </strong>
                  </p>
                )
            )}
          </div>
          <div>
            {Object.values(employee).map(
              (value, index) =>
                index !== 0 && index !== 6 && <p key={value}>{value}</p>
            )}
          </div>
        </div>
      </section>
      <section className="section-content">
        <Formik
          initialValues={{ payReason: "", cashAmount: "$0.00" }}
          onSubmit={(values) => handleCashSubmit(values)}
          innerRef={ref}
        >
          {({ values, handleChange, handleSubmit, setFieldValue }) => (
            <Form className="send-money-form" onSubmit={handleSubmit}>
              {/* {console.log("is Submiiting", isSubmitting)} */}
              {/* {console.log(values.cashAmount)} */}
              {!showConfirm ? (
                <div className={showConfirm ? "hide" : "show"}>
                  <Form.Group className="text-start mb-3">
                    <Form.Label htmlFor="payReason">Payout Reason</Form.Label>
                    <Form.Control
                      type="text"
                      id="payReason"
                      onChange={handleChange}
                    />
                  </Form.Group>

                  <Form.Group className="text-start">
                    <Form.Label htmlFor="cashAmount">Cash Amount</Form.Label>
                    <Form.Control
                      type="text"
                      id="cashAmount"
                      name="cashAmount"
                      onChange={handleChange}
                      minLength={4}
                      maxLength={6}
                      value={values.cashAmount}
                      onBlur={handleCurrencyBlur}
                      onKeyUp={handleCurrencyFormat}
                    />
                  </Form.Group>
                  <Button className="mt-4" onClick={() => handleNext(values)}>
                    Next
                  </Button>
                </div>
              ) : (
                <div className={showConfirm ? "show" : "hide"}>
                  <p>{confirmationMessage}</p>
                  {!showSuccess && (
                    <div>
                      <Button
                        className="mt-4"
                        type="submit"
                        disabled={payoutDisable}
                      // onClick={(values) => handleCashSubmit(values)}
                      >
                        Confirm
                      </Button>
                      <Button
                        className="mt-4"
                        type="button"
                        onClick={(values) => window.location.reload()}
                        style={{ marginLeft: "10px" }}
                      >
                        Back
                      </Button>
                    </div>
                  )}
                </div>
              )}
              {showSuccess && (
                <div className="d-flex align-items-center justify-content-between">
                  <p>{successMessage}</p>
                  <HiHome
                    onClick={() => navigate("/welcome")}
                    style={{ fontSize: "2rem", cursor: "pointer" }}
                  />
                </div>
              )}
            </Form>
          )}
        </Formik>
      </section>
    </section>
  );
}
