export function getFullName(data) {
  if (data) {
    let words = data.split(/(?=[A-Z])/);
    let convertedStr = words
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");

    return convertedStr;
  }
  return "Goode, Marvin";
}

export function formatCurrency(amount) {
  if (amount.slice(-2) === "00") {
    amount = amount.slice(0, -2) + "." + amount.slice(-2);
  } else if (amount.slice(-1) === "0") {
    amount = amount.slice(0, -1) + "." + amount.slice(-1) + "0";
  } else if (amount.slice() !== "0") {
    amount = `${amount}.00`;
  }
  return "$" + amount;
  // return amount.toLocaleString("en-US", {
  //     style: "currency",
  //     currency: "USD",
  //     minimumFractionDigits: 2,
  //     maximumFractionDigits: 2,
  //   });
}

export function formatCamelCase(data) {
  if (typeof data !== "string") {
    return data;
  }

  var words = data.match(/[A-Z][a-z]*/g);
  var newString = words
    .map((word, index) => {
      if (index > 0 && word.length > 0) {
        if (/^[A-Z]/.test(word) && /[A-Z]$/.test(words[index - 1])) {
          return word.charAt(0).toUpperCase() + word.slice(1);
        } else {
          return " " + word.charAt(0).toUpperCase() + word.slice(1);
        }
      } else {
        return word;
      }
    })
    .join("");

  return newString;
}
