// react-datepicker npm docs
// https://reactdatepicker.com/#example-date-range
import DatePicker from "react-datepicker";
import { LuCalendarPlus } from "react-icons/lu";
import "react-datepicker/dist/react-datepicker.css";

export const DateRange = (props) => {
  return (
    <div className="date-filter">
      <span>Search by date range: </span>
      <br />
      <DatePicker
        showIcon
        icon={<LuCalendarPlus />}
        // isClearable
        selected={props.dateFilter.startDate}
        onChange={(date) => props.handleDateRangeFilter({ startDate: date })}
        selectsStart
        startDate={props.dateFilter.startDate}
        endDate={props.dateFilter.endDate}
        placeholderText="Select Start Date"
      />
      <br />
      <DatePicker
        showIcon
        icon={<LuCalendarPlus />}
        // isClearable
        selected={props.dateFilter.endDate}
        onChange={(date) => props.handleDateRangeFilter({ endDate: date })}
        selectsEnd
        startDate={props.dateFilter.startDate}
        endDate={props.dateFilter.endDate}
        minDate={props.dateFilter.startDate}
        placeholderText="Select End Date"
      />
    </div>
  );
};
