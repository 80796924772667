import { useState, useEffect } from "react";
import SelectReportsTable from "../../common/tables/reportsTable";
import { getReports } from "../../../services/payoutServices";
import { getDateRangeReports } from "../../../services/payoutServices";

export default function Reports() {
  const [payouts, setPayouts] = useState([]);
  const [dateFilter, setDateFilter] = useState({
    startDate: null,
    endDate: new Date(),
  });

  const handleDateRangeFilter = (data) => {
    for (let key in data) {
      setDateFilter({ ...dateFilter, [key]: data[key] });
    }
  };

  useEffect(() => {
    getReports().then((res) => {
      const payoutPull = res.data;
      setPayouts(payoutPull);
    });

    if (dateFilter.startDate && dateFilter.endDate) {
      filterByDateRange();
    }
  }, [dateFilter]);

  const [dateRangeFilterResults, setDateRangeFilterResults] = useState([]);
  const filterByDateRange = async () => {
    const { data: getReports } = await getDateRangeReports(
      dateFilter.startDate,
      dateFilter.endDate
    );
    setDateRangeFilterResults(getReports);
    // if (getReports.length > 0) {
    //   setDateRangeFilterResults(getReports);
    // }
  };

  return (
    <section className="main-section">
      <h1>Reports </h1>
      <section className="section-content">
        <SelectReportsTable
          payoutList={payouts}
          handleDateRangeFilter={handleDateRangeFilter}
          dateFilter={dateFilter}
          dateRangeFilterResults={dateRangeFilterResults}
        />
      </section>
    </section>
  );
}
